const marks = [
  {
    value: 0,
    label: "1m",
  },
  {
    value: 11,
    // label: "2m",
  },
  {
    value: 22,
    // label: "3m",
  },
  {
    value: 33,
    // label: "4m",
  },
  {
    value: 44,
    // label: "5m",
  },
  {
    value: 55,
    // label: "6m",
  },
  {
    value: 66,
    // label: "7m",
  },
  {
    value: 77,
    // label: "8m",
  },
  {
    value: 88,
    // label: "9m",
  },
  {
    value: 99,
    label: "10m",
  },
];

export default marks;
