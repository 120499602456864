import Sample from "../Assets/sample.png";
export const SampleArray = [
  {
    id: 1,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLL",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 2,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 3,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 4,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 5,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 6,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 7,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 8,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 9,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 10,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 11,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 12,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
  {
    id: 13,
    asin_link: "https://www.amazon.com/sp?seller=A2OWUYLA50HX3S",
    asin: "e.g.B07238WLLR",
    image: Sample,
    name:
      "e.g. Berri Fit Dragon Fruit Organic Sports Drink Alternative With Natural",
    asin_brand_name: "e.g. Berri Fit",
    manufacturer: "e.g. Berri Pro Inc.",
  },
];
